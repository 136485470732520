#input-form {
    
}

.center {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.logo {
    margin-bottom: 1rem;
}

.banner-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;

}

.banner-card {
    margin-top: 1rem;
    margin-right: 4rem;
}

.banner {
    width: 17vw;
    height: auto;
    -webkit-user-drag: none;
}

.muted-text{
    color: #a8b2ac !important;
}